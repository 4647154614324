import React from "react";
import { useEffect, useState } from "react";
import HeaderComponent from "../Components/HeaderComponent";
import Container from "@mui/material/Container";
import Box from "@mui/system/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import { Alert } from "@mui/material";
import { AlertTitle } from "@mui/material";
import { db, storage } from "../Providers/firebase";
import MicRecorder from "mic-recorder-to-mp3";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import FooterComponent from "../Components/FooterComponent";
import audioBufferToWav from "audiobuffer-to-wav";
import UserForm from "./userFormScreen";
import { ConnectingAirportsOutlined } from "@mui/icons-material";
const { v4: uuidv4 } = require("uuid");

// Initilize the recorder
const Mp3Recorder = new MicRecorder({
  bitRate: 64,
  prefix: "data:audio/wav;base64,",
});

// var list = [

//   "d",

//   "v",

//   "l",

//   "th",

//   "sh",

//   "m",

//   "g",

//   "s",

//   "r",

//   "t",

//   "a",
//   "the",

//   "j",

//   "i",

//   "u",

//   "h",

//   "z",

//   "p",

//   "y",

//   "o",

//   "ch",

//   "n",

//   "c",

//   "f",
// ];


var list = [
  "sound 'bite tongue v'",
  "sound 'j'",
  "sound 'rolled r'",
  "sound 'uh'",
  "sound 'gnn'",
  "sound 'an'",
  "sound 'ch'",
  "sound 'nasalized t' ",
  "sound 'hindi t'",
  "sound 'ee'",
  "sound 'ooon'",
  "sound 'l'",
  "sound 'th'",
  "sound 'a'",
  "sound 'glottal t'",
  "sound 'd'",
  "sound 'fuhh'",
  "sound 'y'",
  "sound 'soft r'",
  "sound 'v'",
  "sound 'hissing s'",
  "sound 'onn'",
  "sound 'b'",
  "sound 'z'",
  "sound 's'",
  "sound 'f'",
  "sound 'flapped r'",
  "sound 'oo'",
  "sound 'sh'",
  "sound 'nasal n'",
  "sound 'forceful-h'",
  "sound 'ph'",
  "sound 't'",
  "sound 'er'",
  "sound 'the'"
];

// var motivation = [    "For a child trapped in silence, your voice could be the breakthrough.",    "Imagine a world where words are just out of reach; your voice can bridge that gap.",    "Every recording is a lifeline to a child struggling to express pain, joy, love.",    "To us, it's a moment; to them, it's a lifetime of being understood.",    "Behind every stutter, pause, or silence is a child yearning to connect; be their voice.",    "Your voice can heal the silent cries of children waiting to be heard.",    "For some kids, every word is a battle; lend your voice to their fight.",    "In the quiet world of a speech-impaired child, your voice can be the echoing roar of hope.",    "Your words today can end the silent tears of a child tomorrow.",    "Where words fail them, let your voice guide; together, we can end their silent struggle."]


function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
}

shuffleArray(list);

// const list = shuffleArray(letters)
// console.log("list");
// console.log(list);

export default function RecordScreen() {
  // State Declaration
  const [isRecording, setIsRecording] = React.useState("");
  const [blobURL, setBlobURL] = React.useState(null);
  const [isBlocked, setIsBlocked] = React.useState(false);
  const [hasRecorded, setHasRecorded] = React.useState("");
  const [recordedData, setRecordedData] = React.useState(null);
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [randomWord, setRandomWord] = React.useState(null);
  const [hasRandomWordLoaded, setHasRandomWordLoaded] = React.useState(false);
  const [randomWordDocumentID, setRandomWordDocumentID] = React.useState(null);
  const [isUploading, setIsUploading] = React.useState(false);
  const [runInterval, setRunInterval] = React.useState(false);
  const [audioUrl, setaudioUrl] = React.useState(null);
  // const [count, setCount] = React.useState(0)
  const [docLength, setdocLength] = React.useState(0);
  const [submittedForm, setSubmitted] = React.useState(false);
  const [random_index, setRandomIndex] = React.useState(0);
  const [presentrecord, setpresent] = React.useState("");
  const [listobject, setListObject] = React.useState({});
  var [currentletter, setCurrentLetter] = React.useState("");
  const [done, setDone] = React.useState(0);
  const [recordedObject, setRecordedObject] = React.useState({});
  const [allownext, setAllowNext] = React.useState(false);

  // // console.log(Object.keys(recordedObject).length == Object.keys(listobject).length)
  // // console.log(Object.keys(recordedObject).length)
  // // console.log(Object.keys(listobject).length)

  // async function FetchRandomWord() {
  //   db.collection('words').onSnapshot(snapshot => {
  //     // Set Random Word
  //     let randomID = Math.floor(Math.random() * snapshot.docs.length)
  //     setRandomIndex(randomID);
  //     let randomWord = snapshot.docs[randomID].data()
  //     let randomWordDocumentID = snapshot.docs[randomID].id
  //     setdocLength(snapshot.docs.length)
  //     setRandomWordDocumentID(randomWordDocumentID)
  //     setRandomWord(randomWord)
  //     setHasRandomWordLoaded(true)
  //     // setCount(count + 1);

  //   })
  // }

  async function fetchWord() {
    db.collection("words").onSnapshot((snapshot) => {
      // console.log("done");
      // console.log(done);

      if (done < list.length) {
        currentletter = list[done];
        setCurrentLetter(currentletter);

        // const pattern = new RegExp(`^${currentletter}/`);

     

        // snapshot.docs.forEach((data) => {
        //   set.push(data.data().word);
        //   if (pattern.test(data.data().word)) {
        //     listobject[data.data().word] = data.data().url
        //       ? [data.data().url, data.data().description]
        //       : ["", data.data().description];
        //   }
        // });

        snapshot.docs.forEach((data) => {
          listobject[data.data().word] = data.data().url
              ? [data.data().url, data.data().description]
              : ["", data.data().description];
        });


        setListObject(listobject);
        // console.log("listobject");
        // console.log(listobject);
        // console.log(Object.keys(listobject).length);
        setHasRandomWordLoaded(true);
      }

      // setCount(count + 1);
    });
  }

  function Cards() {
    const array = Object.keys(listobject).map((key) => ({
      word: key,
      url: listobject[key][0],
      description: listobject[key][1],
    }));
    return (
      <div
        className="list_cards"
        style={{ display: "flex", flexDirection: "column", gap: 20 }}
      >
        {array.map((word) => {
          // console.log("word");
          // console.log(word);

          return (
            <Paper
              sx={{ p: 6, textAlign: "center" }}
              elevation={3}
              key={word.word}
            >
              <h1>{word.word}</h1>
              <p>{word.description}</p>
              {hasRecorded == word.word ||
              (recordedObject[word.word] != null &&
                recordedObject[word.word]) ? (
                (uploadProgress == 100 && presentrecord == word.word) ||
                recordedObject[word.word] ? (
                  <>
                    <Box sx={{ textAlign: "left" }}>
                      <Alert severity="success">
                        <AlertTitle>Thank You.</AlertTitle>
                        We Recorded the Audio for this sound Successfully.
                      </Alert>

                      {/* <Button sx={{ mt: 4 }} style={{ fontSize: '14px', fontWeight: '600', fontFamily: 'Poppins' }}>
                        Next Word
                      </Button> */}
                    </Box>
                  </>
                ) : (
                  <>
                    <Box sx={{ textAlign: "left" }}>
                      <Alert severity="warning">
                        <AlertTitle>Your Audio has been recorded.</AlertTitle>
                        Listen the audio and then press the confrim button to
                        submit your audio.
                        <Box sx={{ mt: 2, mb: 4 }}>
                          <audio
                            id={word.word}
                            style={{ display: "none" }}
                            src={blobURL}
                            controls
                          />

                          <Button
                            onClick={() => ListenRecording(word.word)}
                            variant="contained"
                            color="secondary"
                          >
                            Listen Recording
                          </Button>
                        </Box>
                        {uploadProgress == 0 && presentrecord == word.word ? (
                          <>
                            <Button
                              sx={{ m: 1 }}
                              onClick={() => UploadToDatabase(word.word)}
                              disabled={isUploading}
                              variant="contained"
                            >
                              Confirm and Upload
                            </Button>
                            <Button
                              sx={{ m: 1 }}
                              onClick={DiscardRecording}
                              disabled={isUploading}
                              variant="outlined"
                              color="error"
                            >
                              Record Again
                            </Button>
                          </>
                        ) : (
                          <>
                            <LinearProgress
                              variant="determinate"
                              value={uploadProgress}
                            />

                            {/* <Button sx={{ mt: 4 }} style={{ fontSize: '14px', fontWeight: '600', fontFamily: 'Poppins' }}>
                                Next Word
                              </Button> */}
                          </>
                        )}
                      </Alert>
                    </Box>
                  </>
                )
              ) : isRecording != word.word ? (
                <>
                  <audio
                    style={{ display: "none" }}
                    id={`fetchAudio${word.word}`}
                    src={word.url}
                    controls
                  ></audio>
                  <br />
                  <Button
                    onClick={() => ListenRecordin(word.word)}
                    variant="outlined"
                    color="success"
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                      color: "#008000",
                      margin: "10px",
                    }}
                  >
                    <VolumeUpIcon />
                    Listen
                  </Button>
                  <Button
                    onClick={() => start(word.word)}
                    variant="outlined"
                    color="error"
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                      color: "#dc3545",
                    }}
                  >
                    <KeyboardVoiceIcon />
                    Speak Now
                  </Button>{" "}
                  <br />
                  {/* <Button sx={{ mt: 4 }} style={{ fontSize: '14px', fontWeight: '600', fontFamily: 'Poppins' }}>
                      Next Word
                    </Button> */}
                </>
              ) : (
                <Button
                  onClick={() => stopFunction(word.word)}
                  variant="contained"
                  color="error"
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                    color: "#fff",
                  }}
                >
                  <KeyboardVoiceIcon />
                  Stop Recording
                </Button>
              )}
            </Paper>
          );
        })}
      </div>
    );
  }

  useEffect(() => {
    // Get Words List From Database

    fetchWord();

    navigator.getUserMedia(
      { audio: true },
      () => {
        setIsBlocked(false);
      },
      () => {
        alert(
          "Access to Microphone Is Blocked. Please Allow Access to Microphone in Settings."
        );
        setIsBlocked(true);
      }
    );
  }, []);

  // Start Recording Function
  function start(a) {
    setpresent(a);
    setUploadProgress(0);
    setIsUploading(false);
    if (isBlocked) {
      alert(
        "Access to Microphone Is Blocked. Please Allow Access to Microphone in Settings."
      );
      
    } else {
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(a);
        })
        .catch((e) => console.error(e));
    }
  }
  // async function fetchAudio() {
  //   const ref = db.collection('audiometadata');
  //   const snapshot = (await ref.get()).docs[random_index].data();
  //   setaudioUrl(snapshot.url);

  //   ListenRecordin();
  // }
  async function ListenRecordin(a) {
    let audioID = "fetchAudio" + a;
    let audio = document.getElementById(audioID);

  if (audio && audio.src) {
    audio.play().catch(error => {
      console.error("Error playing audio:", error);
    });
  } else {
    console.error(`Audio with ID ${audioID} not found or has no source.`);
  }

  }

  // Stop Recording Functin
  async function stopFunction(a) {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        const binaryString = btoa(blobURL);
        setBlobURL(blobURL);
        setIsRecording("");
        const audio = new Audio(blobURL);
        setRecordedData(blob);
        setHasRecorded(a);
      })
      .catch((e) => console.error(e));
  }

  // Discard Recording and Nullify all states
  const DiscardRecording = () => {
    setIsUploading(false);
    setIsRecording("");
    setHasRecorded("");
    setBlobURL(null);
    setRecordedData(null);
  };

  // Function to Upload Recording to Database
  async function UploadToDatabase(a) {
    setIsUploading(true);
    let blob = recordedData;
    const path = `phonemes/${a}/${new Date().getTime()}`
    const uploadTask = storage
      .ref(path)
      .put(blob);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // progress function
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setUploadProgress(progress);
      },
      (error) => {
        // error function
      },
      () => {
        // complete function
        storage
          .ref(`phonemes/${a}/`)
          .child(uploadTask.snapshot.ref.name)
          .getDownloadURL()
          .then(async (url) => {
            db.collection("audiometadata").add({
              url: url,
              word: a,
              recorder: id,
              path: path
            });

            const docRef = db.collection("recorders").doc(id);

            try {
              let l = (await docRef.get()).data();
              // console.log(l.data());
              let new_list = { ...l.recordingURLs};
              console.log("new_list");
              console.log(new_list);


              new_list[a] = url;
              console.log(new_list);
              docRef.update({ recordingURLs: new_list });
              // console.log("Document updated successfully!");
            } catch (error) {
              console.error("Error updating document:", error);
            }
            var docid = "";
            var query = db.collection("words").where("word", "==", a);
            (await query.get()).forEach((data) => {
              docid = data.id;
            });

            // const ref = db.collection("words").doc(docid);

            // ref.update({ url: url }).then(() => {
              setIsUploading(false);

              recordedObject[a] = true;

              setRecordedObject(recordedObject);

              // console.log("recordedObject");
              // console.log(recordedObject);
              // console.log(Object.keys(recordedObject).length);

              if (
                Object.keys(recordedObject).length ==
                Object.keys(listobject).length
              ) {
                setAllowNext(true);
              }
              else{
                setAllowNext(false);
              }
            // }
            // );
          });
      }
    );
  }

  // Load Next Word Function -
  async function LoadNextWord() {
    setUploadProgress(0);
    DiscardRecording();
    setDone(done + 1);
    setRecordedObject({});
    setListObject({});
    await fetchWord();

    if (
      Object.keys(recordedObject).length ==
      Object.keys(listobject).length
    ) {
      setAllowNext(true);
    }
    else{
      setAllowNext(false);
    }
  }

  // Listen to Recording By User
  async function ListenRecording(a) {
    let audio = await document.getElementById(a);
    audio.play();
  }

  // Turn off recording after 10 Seconds
  var [id, setid] = React.useState("");

  const [formData, setFormData] = useState({
    uuid: "",
    Name: "",
    NativePlace: "",
    email: "",
    gender: "",
    age: "",
    economicBackground: "",
    dialects: "",
    speechProblems: "",
    knowsPerson: "", // New field for previous speech problems
    recordingURLs: {},
  });

  const [error, setError] = React.useState(null);

  const handleInputChange = (event) => {
    const { name, value, type } = event.target;
    let newValue = value;

    if(type === "text"){
      if (value.trim() === "") {
        setError("Name cannot be blank.");
      } else {
        setError(null); // Clear the error message if the input is not blank
      }
    }
    else if (type === "checkbox") {
      newValue = formData[name] === value ? "" : value;
    } else if (type === "select") {
      newValue = value;
    } else if (type === "radio") {
      newValue = value;
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    var new_uuid = uuidv4();

    formData.uuid = new_uuid;

    db.collection("recorders").add(formData);
    const ref = db.collection("recorders");

    setSubmitted(true);
    var query = db.collection("recorders").where("uuid", "==", new_uuid);
    (await query.get()).forEach((data) => {
      setid(data.id);
    });
  };

  async function Refresh() {
    window.location.reload();
  }

  return hasRandomWordLoaded ? (
    <>
      <HeaderComponent />

      {/* Recording Container With Top margin in center card box */}
      {!submittedForm ? (
        <div className="form" >
          <h1>User Form</h1>
          <div style={{textAlign:"center", color: "pink"}}>
          <p>Each recording you make helps a child speak better. </p> 
          <p>Let's do our best for these kids. </p>
          </div>
          <form onSubmit={handleSubmit}>
            <div>
              <label>Name:</label>
              <input
                type="text"
                name="Name"
                value={formData.Name}
                required
                onChange={handleInputChange}
              />
              {error && <p style={{ color: "red" }}>{error}</p>}
            </div>
            <div>
              <label>Native place:</label>
              <input
                type="text"
                name="NativePlace"
                value={formData.NativePlace}
                required
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Email:</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                required
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Age:</label>
              <input
                type="number"
                name="age"
                value={formData.age}
                required
                onChange={handleInputChange}
                onWheel={() => document.activeElement.blur()}
              />
            </div>

            <div className="checkbox-group">
              <label>Gender:</label>
              <label>
                <input
                  type="radio"
                  name="gender"
                  value="male"
                  required
                  checked={formData.gender === "male"}
                  onChange={handleInputChange}
                />{" "}
                Male
              </label>
              <label>
                <input
                  type="radio"
                  name="gender"
                  value="female"
                  checked={formData.gender === "female"}
                  onChange={handleInputChange}
                />{" "}
                Female
              </label>
            </div>
            <div>
              <label>
                Do you know anyone in your family who suffers from any speech impariment?
              </label>
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    name="knowsPerson"
                    value="yes"
                    required
                    checked={formData.knowsPerson === "yes"}
                    onChange={handleInputChange}
                  />{" "}
                  Yes
                </label>
                <label>
                  <input
                    type="radio"
                    name="knowsPerson"
                    value="no"
                    checked={formData.knowsPerson === "no"}
                    onChange={handleInputChange}
                  />{" "}
                  No
                </label>
              </div>
            </div>
            <div>
              <label>Economic Background:</label>
              <select
                name="economicBackground"
                value={formData.economicBackground}
                onChange={handleInputChange}
                required
              >
                <option value="">Select...</option>
                <option value="low">less than 5L</option>
                <option value="medium">5L to 9L</option>
                <option value="high">9L-20L</option>
                <option value="rich">more than 20L</option>
              </select>
            </div>

            <div>
              <label>Any speech realted problems you have now or in the past:</label>
              <select
                name="speechProblems"
                value={formData.speechProblems}
                required
                onChange={handleInputChange}
              >
                <option value="">Select...</option>
                <option value="stuttering">Stuttering or Stammering</option>
                <option value="speech_sound">Speech Sound Disorders</option>
                <option value="apraxia">Childhood Apraxia of Speech</option>
                <option value="aphasia">Aphasia</option>
                <option value="cleft_lip_palate">
                  Cleft Lip and/or Palate
                </option>
                <option value="dysarthria">Dysarthria</option>
                <option value="hearing_loss">Hearing Loss</option>
                <option value="oral_motor">Oral Motor Problems</option>
                <option value="developmental_delays">
                  Developmental Delays
                </option>
                <option value="traumatic_brain_injury">
                  Traumatic Brain Injury (TBI)
                </option>
                <option value="neurological_conditions">
                  Neurological Conditions
                </option>
                <option value="none">None</option>
              </select>
            </div>
            <div>
              <label>Dialects:</label>
              <select
                name="dialects"
                value={formData.dialects}
                required
                onChange={handleInputChange}
              >
                <option value="">Select...</option>
                <option value="no">Hindi with no accent</option>
                <option value="haryanvi">Haryanvi (Haryana)</option>
                
                
                <option value="bhojpuri">
                  Bhojpuri (Bihar, Eastern Uttar Pradesh)
                </option>
                
                
                
                <option value="maithili">Maithili (Bihar, Jharkhand)</option>
                
                
                <option value="punjabi">Punjabi</option>
                <option value="marwari">Marwari (Marwar region)</option>
                <option value="mewari">Mewari (Udaipur region)</option>
                <option value="shekhawati">
                  Shekhawati (Shekhawati region)
                </option>
                <option value="mewati">
                  Mewati (Alwar and Bharatpur regions)
                </option>
                <option value="bengali">Bengali</option>
                <option value="odia">Odia</option>
                <option value="assamese">Assamese</option>
                
                
                <option value="gujarati">Gujarati</option>
                <option value="marathi">
                  Marathi 
                </option>
                <option value="telugu">
                  Telugu (Andhra region)
                </option>
                <option value="tamil">Tamil</option>
                <option value="kannada">
                  Kannada (Karnataka)
                </option>
                <option value="malayalam">
                  Malayalam
                </option>
                <option value="tulu">
                  Tulu (Coastal Karnataka, mainly in Mangalore and Udupi
                  districts)
                </option>
                {/* Add more options as needed */}
              </select>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      ) : (
        <Container>
          <Container maxWidth="sm" sx={{ mt: 10 }}>
            { 1 == done ? ( // list.length
              <>
                {/* <div className="card"> */}
                <div
                  className="card-body"
                  style={{
                    justifyContent: "center",
                    textAlign: "center",
                    position: "relative",
                    alignItems: "center",
                  }}
                >
                  <h5 className="card-title">Success!</h5>
                  <p className="card-text">
                    All voice samples have been recorded
                  </p>

                  <Button
                    sx={{ mt: 4 }}
                    onClick={Refresh}
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                    variant="outlined"
                  >
                    New Session
                  </Button>
                </div>
                {/* </div> */}
              </>
            ) : (
              <div
                style={{
                  justifyContent: "center",
                  textAlign: "center",
                  position: "relative",
                  alignItems: "center",
                }}
              >
               <div style={{textAlign:"center", color: "pink"}}>
          <p>Each recording you make helps a child speak better. </p> 
          <p>Let's do our best for these kids. </p>
          </div>
                <h1 className="card-title" style={{ font: "42" }}>
                  Record the following words
                </h1>
                <LinearProgress variant="determinate" value={done * 1} />
                <p> {done} / 1</p>
                <h3 className="card-text">
                  Before speaking please hear the sample sound by pressing the
                  listen button.
                </h3>
                <Cards />
                <Button
                  sx={{ mt: 4 }}
                  onClick={LoadNextWord}
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    fontFamily: "Poppins",
                  }}
                  disabled={!allownext}
                  variant="outlined"
                >
                  Next Word
                </Button>
              </div>
            )}
          </Container>
        </Container>
      )}

      <Box sx={{ mt: 6 }}>
        <FooterComponent />
      </Box>
    </>
  ) : (
    <>
      <Container maxWidth="sm" sx={{ mt: 10 }}>
        <Paper sx={{ p: 6, textAlign: "center" }} elevation={0}>
          <CircularProgress />
          <p>Loading...</p>
        </Paper>
      </Container>
      <Box sx={{ mt: 6 }}>
        <FooterComponent />
      </Box>
    </>
  );
}
